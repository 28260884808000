export const adapterKeys = ['loan-reconciliation', 'property-valuation', 'data-export', 'base-rates'] as const;
/**
 * Adapters are internal services that handle communication with the customers' services. Since every customer's
 * api and authorization flow is going to be different, we need different adapters for each customer.
 *
 * Currently, we have adapters for
 * - loan-reconciliation: matching our loans with the customer's loans, and importing any that are missing in our system
 * - property-valuation: fetching property valuations from the customer's system
 * - data-export: upload select parts of the customer's data to a server they have set up somewhere. The api must be stable
 * - base-rates: fetch base rates from the customer's system
 */
export type AdapterKey = (typeof adapterKeys)[number];
export const isAdapterKey = (v: unknown): v is AdapterKey => adapterKeys.includes(v as AdapterKey);
