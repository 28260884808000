export function percentageToFraction(value: number | undefined | ''): number | undefined {
  if (value === undefined || value === '') return undefined;
  return value / 100;
}

/**
 * Percentage to fraction conversion that preserves the significant digits
 *
 * Example: precisePercentageToFraction(11.8) === 0.118 whereas 11.8 / 100 === 0.11800000000000001
 *
 * The utility is implemented using plain string manipulations and therefore can
 * be used in the front end without a dependence on a general decimal library.
 */
export function precisePercentageToFraction(percentage: number): number {
  if (percentage < 0) {
    return -precisePercentageToFraction(-percentage);
  }
  const parts = percentage.toString().split('.');
  const integerPart = parts[0].padStart(2, '0');
  const index = integerPart.length - 2;
  const [first, rest] = [integerPart.slice(0, index), integerPart.slice(index)];
  const str = [first, '.', rest, parts[1] ?? ''].join('');
  const result = parseFloat(str);
  if (isNaN(result)) {
    throw Error(`Internal error in percentage conversion (${percentage}, ${str})`);
  }
  return result;
}

/**
 * Fraction to percentage conversion that preserves the significant digits
 *
 * Example: preciseFractionToPercentage(0.035) === 3.5 whereas 0.035 * 100 === 3.5000000000000004.
 */
export function preciseFractionToPercentage(fraction: number): number {
  if (fraction < 0) {
    return -preciseFractionToPercentage(-fraction);
  }
  const parts = fraction.toString().split('.');
  if (parts[1] === undefined) {
    return fraction * 100;
  }
  const fractionPart = parts[1].padEnd(2, '0');
  const [first, rest] = [fractionPart.slice(0, 2), fractionPart.slice(2)];
  const str = [parts[0], first, '.', rest].join('');
  const result = parseFloat(str);
  if (isNaN(result)) {
    throw Error(`Internal error in fraction conversion (${fraction}, ${str})`);
  }
  return result;
}
