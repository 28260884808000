import { defineApi } from '../common/api-definition';
import { SuccessOrErrorType } from '../common/SuccessOrErrorType';
import { ObjectValidationIssueType } from '../common/ValidationIssueType';
import { SLLifecycleRunLoan } from './LifecycleRunLoanType';
import { PartiesRunLoan } from './RunLoanType';

export type StructuredLoanValidationBody = {
  lifecycle?: SLLifecycleRunLoan;
  parties?: PartiesRunLoan;
};

export type StructuredLoanValidationIssue = ObjectValidationIssueType<StructuredLoanValidationBody>;

export type StructuredLoanValidationIssueRequest = {
  loanId: string;
};

export const structuredLoanValidationIssueApi = defineApi<{
  body: StructuredLoanValidationIssueRequest;
  response: { issues?: StructuredLoanValidationIssue };
}>({
  url: 'structured-loans/validation-issue',
  method: 'post',
});

export type StructuredLoanSuccessOrError = SuccessOrErrorType<true, StructuredLoanValidationIssue | undefined>;
