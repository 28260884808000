import { defineApi } from '../common/api-definition';
import { LCELoanValidationMap } from '../loan/loan-validation';
import { ObjectStripUnknown, RequiredArray } from '../validation/atoms';
import { NonEmptyString } from '../validation/base';

export type ValidateLoansRequest = {
  loanIds: string[];
};

export const validateLoansRequestValidation = ObjectStripUnknown({
  loanIds: RequiredArray(NonEmptyString.required()),
}).required();

export type ValidateLoansResponse = {
  loanValidations: LCELoanValidationMap;
};

export const validateLoansApi = defineApi<{
  body: ValidateLoansRequest;
  response: ValidateLoansResponse;
}>({
  url: 'platform-admin/validate-loans',
  method: 'post',
});
