/**
 * These utility functions were adapted from @practical-fp/union-types, but they work on the
 * discriminated unions returned from the lifecycling and used elsewhere in the system, where
 * the `tagLce` is called `type`, the value is always an object, and the type is recorded directly
 * in the value.
 */

/**
 * A type which discriminates on {@link type}
 * when used in a union with other instances of this type.
 *
 * @example
 * type Union =
 *     | LceVariant<"1", {}>
 *     | LceVariant<"2", {2: number}>
 */
export type LceVariant<Type extends string = string, Value extends object = object> = Value & {
  readonly type: Type;
};

/**
 * Utility type which allows any {@link LceVariant} to be assigned to it.
 */
export type AnyLceVariant = LceVariant<string, object>;

/**
 * Creates a new {@link LceVariant} instance whose value is the empty object.
 * @param type
 */
export function tagLce<Tag extends string>(type: Tag): LceVariant<Tag>;

/**
 * Creates a new {@link LceVariant} instance.
 * @param type
 * @param value
 */
export function tagLce<Tag extends string, Value extends object>(type: Tag, value: Value): LceVariant<Tag, Value>;
export function tagLce(type: string, value: object = {}): AnyLceVariant {
  return {
    ...value,
    type,
  };
}

/**
 * Extracts the value form a {@link LceVariant} instance.
 */
export function untagLce<Value extends object>(variant: LceVariant<string, Value>): Value {
  return variant;
}

/**
 * Utility type for extracting the possible values for {@link LceVariant#type}
 * from a union of {@link LceVariant}s.
 *
 * @example
 * type Union =
 *     | LceVariant<"1">
 *     | LceVariant<"2">
 *
 * // Equals: "1" | "2"
 * type UnionTags = LceTags<Union>
 */
export type LceTags<Var extends AnyLceVariant> = Var['type'];

/**
 * Utility type for extracting the possible types for {@link LceVariant#value}
 * from a union of {@link LceVariant}s.
 *
 * @example
 * type Union =
 *     | LceVariant<"1", string>
 *     | LceVariant<"2", number>
 *
 * // Equals: string | number
 * type UnionValues = LceValues<Union>
 */
export type LceValues<Var extends AnyLceVariant> = Var;

/**
 * Utility type for narrowing down a union of {@link LceVariant}s based on their tags.
 *
 * @example
 * type Union =
 *     | LceVariant<"1", 1>
 *     | LceVariant<"2", 2>
 *     | LceVariant<"3", 3>
 *
 * // Equals: LceVariant<"1", 1> | LceVariant<"3", 3>
 * type Narrowed = LceNarrow<Union, "1" | "3">
 */
export type LceNarrow<Var extends AnyLceVariant, Tag extends LceTags<Var>> = Extract<Var, LceVariant<Tag, object>>;

/**
 * Type guard for narrowing down the type of a {@link LceVariant}.
 * @param variant
 * @param type
 * @example
 * type Union =
 *     | LceVariant<"1", number>
 *     | LceVariant<"2", string>
 *
 * function doSomething(union: Union) {
 *     // union.value has type number | string
 *
 *     if (lceHasTag(union, "1")) {
 *         // union.value has type number now
 *     }
 * }
 */
export function lceHasTag<Var extends AnyLceVariant, Tag extends LceTags<Var>>(
  variant: Var,
  type: Tag
): variant is LceNarrow<Var, Tag> {
  return variant.type === type;
}

/**
 * Type of a function which narrows down the type of a given {@link LceVariant}.
 */
export type LcePredicate<Tag extends string> = <Var extends AnyLceVariant>(
  variant: Var
) => variant is LceNarrow<Var, Tag>;

/**
 * Factory function for creating a type guard which narrows down the type of a {@link LceVariant}.
 * @param type
 * @example
 * type Union =
 *     | LceVariant<"1", number>
 *     | LceVariant<"2", string>
 *
 * function doSomething(list: Union[]) {
 *     // filtered has type LceVariant<"1", number>[]
 *     const filtered = list.filter(lcePredicate("1"))
 * }
 */
export function lcePredicate<Tag extends string>(type: Tag): LcePredicate<Tag> {
  return <Var extends AnyLceVariant>(variant: Var): variant is LceNarrow<Var, Tag> => lceHasTag(variant, type);
}

/**
 * Symbol for declaring a wildcard case in a {@link lceMatch} expression.
 */
export const LCE_WILDCARD = Symbol('Match Wildcard Type');

/**
 * Utility type for ensuring that a {@link lceMatchExhaustive} expression covers all cases.
 */
export type LceCasesExhaustive<Var extends AnyLceVariant, Ret = unknown> = {
  [Tag in LceTags<Var>]: (value: LceValues<LceNarrow<Var, Tag>>) => Ret;
};

/**
 * Utility type for enabling a {@link lceMatchWildcard} expression to cover only some cases,
 * as long as, a wildcard case is declared for matching the remaining cases.
 */
export type LceCasesWildcard<Var extends AnyLceVariant, Ret = unknown> = Partial<LceCasesExhaustive<Var, Ret>> & {
  [LCE_WILDCARD]: () => Ret;
};

/**
 * Utility type for ensuring that a {@link lceMatch} expression either covers all cases,
 * or contains a wildcard for matching the remaining cases.
 */
export type LceCases<Var extends AnyLceVariant, Ret = unknown> =
  | LceCasesExhaustive<Var, Ret>
  | LceCasesWildcard<Var, Ret>;

/**
 * Utility type for inferring the return type of a {@link lceMatch} expression.
 */
export type LceCasesReturn<Var extends AnyLceVariant, C extends LceCases<Var>> = C extends LceCases<Var, infer Ret>
  ? Ret
  : never;

/**
 * Function for matching on the type of a {@link LceVariant}.
 * All possible cases need to be covered, unless a wildcard case is present.
 * @param variant
 * @param cases
 * @example
 * type Union =
 *     | LceVariant<"Num", number>
 *     | LceVariant<"Str", string>
 *     | LceVariant<"Bool", boolean>
 *
 * function doSomething(union: Union) {
 *     return lceMatch(union, {
 *         Num: number => number * number,
 *         Str: string => `Hello, ${string}!`,
 *         Bool: boolean => !boolean,
 *     })
 * }
 *
 * function doSomethingElse(union: Union) {
 *     return lceMatch(union, {
 *         Str: string => `Hello, ${string}!`,
 *         [LCE_WILDCARD]: () => "Hello there!",
 *     })
 * }
 */
function lceMatch<Var extends AnyLceVariant, C extends LceCases<Var>>(variant: Var, cases: C): LceCasesReturn<Var, C> {
  const type = variant.type;
  if (typeof (cases as any)[type] === 'function') {
    return (cases as any)[type](variant);
  } else if (typeof (cases as any)[LCE_WILDCARD] === 'function') {
    return (cases as any)[LCE_WILDCARD]();
  }
  throw new Error(`No case matched type ${type}.`);
}

/**
 * Helper type to restrict the possible keys of a type.
 *
 * This is useful for {@link lceMatchExhaustive} and {@link lceMatchWildcard} where the cases argument
 * needs to be generic to infer the correct return type.
 * However, due to the argument being generic it is allowed to pass extra properties.
 * Passing extra arguments is probably a spelling mistake.
 * Therefore, we restrict the properties by setting extra properties to never.
 *
 * Typescript 4.2 will show a nice hint asking whether you've misspelled the property name.
 */
export type LceValidateProperties<T, AllowedProperties extends keyof T> = {
  [_ in Exclude<keyof T, AllowedProperties>]: never;
};

/**
 * Function for matching on the type of a {@link LceVariant}.
 * All possible cases need to be covered.
 * @param variant
 * @param cases
 * @example
 * type Union =
 *     | LceVariant<"Num", number>
 *     | LceVariant<"Str", string>
 *     | LceVariant<"Bool", boolean>
 *
 * function doSomething(union: Union) {
 *     return lceMatchExhaustive(union, {
 *         Num: number => number * number,
 *         Str: string => `Hello, ${string}!`,
 *         Bool: boolean => !boolean,
 *     })
 * }
 */
export function lceMatchExhaustive<Var extends AnyLceVariant, LceCases extends LceCasesExhaustive<Var>>(
  variant: Var,
  cases: LceCases & LceValidateProperties<LceCases, keyof LceCasesExhaustive<Var>>
): LceCasesReturn<Var, LceCases> {
  return lceMatch(variant, cases);
}

export function lceOptMatchExhaustive<Var extends AnyLceVariant, LceCases extends LceCasesExhaustive<Var>>(
  variant: Var | undefined,
  cases: LceCases & LceValidateProperties<LceCases, keyof LceCasesExhaustive<Var>>
): LceCasesReturn<Var, LceCases> | undefined {
  return variant != null ? lceMatch(variant, cases) : undefined;
}

/**
 * Function for matching on the type of a {@link LceVariant}.
 * Not all cases need to be covered, a wildcard case needs to be present.
 * @param variant
 * @param cases
 * @example
 * type Union =
 *     | LceVariant<"Num", number>
 *     | LceVariant<"Str", string>
 *     | LceVariant<"Bool", boolean>
 *
 * function doSomething(union: Union) {
 *     return lceMatchWildcard(union, {
 *         Str: string => `Hello, ${string}!`,
 *         [LCE_WILDCARD]: () => "Hello there!",
 *     })
 * }
 */
export function lceMatchWildcard<Var extends AnyLceVariant, LceCases extends LceCasesWildcard<Var>>(
  variant: Var,
  cases: LceCases & LceValidateProperties<LceCases, keyof LceCasesWildcard<Var>>
): LceCasesReturn<Var, LceCases> {
  return lceMatch(variant, cases);
}

/**
 * Type which specifies the lceConstructor for a variant type.
 */
export type LceConstructor<Tag extends string, Value extends object> = <T extends Value>(
  value: T
) => LceVariant<Tag, T>;

/**
 * Type which specifies the strict lceConstructor for a variant type.
 * It does not support generics.
 */
export type LceStrictConstructor<Tag extends string, Value extends object> = (value: Value) => LceVariant<Tag, Value>;

/**
 * Type which specifies the extra properties which are attached to a lceConstructor.
 */
export interface LceConstructorExtra<Tag extends string> {
  type: Tag;
  is: LcePredicate<Tag>;
}

/**
 * Type which specifies the lceConstructor for a variant type with attached type guard.
 */
export type LceConstructorWithExtra<Tag extends string, Value extends object> = LceConstructor<Tag, Value> &
  LceConstructorExtra<Tag>;

/**
 * Type which specifies the strict lceConstructor for a variant type with attached type guard.
 * It does not support generics.
 */
export type LceStrictConstructorWithExtra<Tag extends string, Value extends object> = LceStrictConstructor<Tag, Value> &
  LceConstructorExtra<Tag>;

/**
 * Function for creating a lceConstructor for the given variant.
 *
 * In case the variant type uses unconstrained generics,
 * pass unknown as its type arguments.
 *
 * In case the variant type uses constrained generics,
 * pass the constraint type as its type arguments.
 *
 * Use {@link lceImpl} instead if your environment has support for {@link Proxy}.
 *
 * @example
 * type Result<T, E> =
 *     | LceVariant<"Ok", T>
 *     | LceVariant<"Err", E>
 *
 * const Ok = lceConstructor<Result<unknown, unknown>, "Ok">("Ok")
 * const Err = lceConstructor<Result<unknown, unknown>, "Err">("Err")
 *
 * let result: Result<number, string>
 * result = Ok(42)
 * result = Err("Something went wrong")
 *
 * Ok.is(result)  // false
 * Err.is(result)  // true
 *
 * Ok.type  // "Ok"
 * Err.type  // "Err"
 */
export function lceConstructor<Var extends AnyLceVariant, Tag extends LceTags<Var>>(
  tagName: Tag
): LceConstructorWithExtra<Tag, LceValues<LceNarrow<Var, Tag>>> {
  function lceConstructor<T extends object>(value: T) {
    return tagLce(tagName, value);
  }

  lceConstructor.type = tagName;
  lceConstructor.is = lcePredicate(tagName);
  return lceConstructor;
}

/**
 * Same as {@link lceConstructor}, but does not support generics.
 * @param tagName
 */
export function lceStrictConstructor<Var extends AnyLceVariant, Tag extends LceTags<Var>>(
  tagName: Tag
): LceStrictConstructorWithExtra<Tag, LceValues<LceNarrow<Var, Tag>>> {
  return lceConstructor(tagName);
}

/**
 * Type which specifies constructors and type guards for a variant type.
 */
export type LceImpl<Var extends AnyLceVariant> = {
  [Tag in LceTags<Var>]: LceConstructorWithExtra<Tag, LceValues<LceNarrow<Var, Tag>>>;
};

/**
 * Type which specifies strict constructors and type guards for a variant type.
 * It does not support generics.
 */
export type LceStrictImpl<Var extends AnyLceVariant> = {
  [Tag in LceTags<Var>]: LceStrictConstructorWithExtra<Tag, LceValues<LceNarrow<Var, Tag>>>;
};

/**
 * Function for generating an implementation for the given variants.
 *
 * In case the variant type uses unconstrained generics,
 * pass unknown as its type arguments.
 *
 * In case the variant type uses constrained generics,
 * pass the constraint type as its type arguments.
 *
 * @example
 * type Result<T, E> =
 *     | LceVariant<"Ok", T>
 *     | LceVariant<"Err", E>
 *
 * const {Ok, Err} = lceImpl<Result<unknown, unknown>>()
 *
 * let result: Result<number, string>
 * result = Ok(42)
 * result = Err("Something went wrong")
 *
 * Ok.is(result)  // false
 * Err.is(result)  // true
 *
 * Ok.type  // "Ok"
 * Err.type  // "Err"
 */
export function lceImpl<Var extends AnyLceVariant>(): LceImpl<Var> {
  return new Proxy({} as LceImpl<Var>, {
    get: <Tag extends keyof LceImpl<Var>>(_: LceImpl<Var>, tagName: Tag) => {
      return lceConstructor<Var, Tag>(tagName);
    },
  });
}

/**
 * Same as {@link lceImpl}, but does not support generics.
 */
export function lceStrictImpl<Var extends AnyLceVariant>(): LceStrictImpl<Var> {
  return lceImpl();
}
