import { Currency, matchString, numberFormatter } from '@realstocks/types';
import classNames from 'classnames';
import { FastField, Field, FieldAttributes, useField, useFormikContext } from 'formik';
import { isNumber, omit } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import RsInfo from '../../rs-elements/rs-info/RsInfo';
import RsLoading from '../../rs-loading/RsLoading';
import RsLabel from '../rs-label/RsLabel';
import RsFieldError from '../RsFieldError';
import { rsFieldDisabledClass, RsFormContext } from '../RsForm';
import { getInputErrorClass } from '../utils/form-helpers';
import './RsInput.scss';

type Props = {
  id: string;
  label: string;
  icon?: string;
  children?: React.ReactNode; // tooltip
  showResetButton?: Function;
  onResetClicked?: Function;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: Function;
  fullWidth?: boolean;
  labelAfterInput?: string;
  labelBeforeInput?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  enforceFormatter?: boolean;
  loading?: boolean;
  hideError?: boolean;
  fastField?: boolean;
  format?: InputFormatType;
  mode?: 'view' | 'edit';
} & FieldAttributes<any>;

export default function RsInput(props: Props) {
  const [field, meta] = useField(props);
  const rsInputComponentRef = useRef<HTMLDivElement>(null);
  const [amountFormatter, setAmountFormatter] = useState(
    (props.type && props.type === 'number') || props.enforceFormatter
  );
  const rsFormContext = useContext(RsFormContext);
  const { showValidatingText, getFieldIssues, ignoreRequireTouched, loading: formLoading } = rsFormContext;
  const mode = props.mode ?? rsFormContext.mode;

  const { isValidating: isValidatingGlobal, setFieldTouched } = useFormikContext();

  const [listeningToEvents, setListeningToEvents] = useState(false);
  const [isValidating, setIsValidating] = useState<boolean>(false);

  const {
    id,
    name,
    type,
    placeholder,
    loading,
    hideError = false,
    fastField = false,
    format = defaultFormatFromLabel(props.labelAfterInput || props.labelBeforeInput),
  } = props;

  useEffect(() => {
    if (!isValidatingGlobal) {
      setIsValidating(false);
    }
  }, [isValidatingGlobal]);

  useEffect(() => {
    setAmountFormatter(true);
  }, []);

  useEffect(() => {
    setAmountFormatter((type && type === 'number') || props.enforceFormatter);
  }, [type, props.enforceFormatter]);

  useEffect(() => {
    if (!amountFormatter && listeningToEvents) {
      const inputEl = rsInputComponentRef.current?.querySelector('input');

      if (inputEl) inputEl.focus();
    }
  }, [amountFormatter, listeningToEvents]);

  const inputProps = omit(props, [
    'value',
    'onChange',
    'onBlur',
    'children',
    'showResetButton',
    'onResetClicked',
    'fullWidth',
    'required',
    'labelAfterInput',
    'labelBeforeInput',
    'enforceFormatter',
    'loading',
    'hideError',
  ]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(e);

    if (props.onChange) {
      props.onChange(e);
    }
  };

  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if ((type && type === 'number') || props.enforceFormatter) {
      setAmountFormatter(true);
    }
    setFieldTouched(field.name, true, false);
    setIsValidating(true);
    field.onBlur(e);
    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  const style: any = {};

  if (props.icon) {
    style.paddingLeft = '34px';
  }

  if (props.labelAfterInput) {
    style.paddingRight = `calc(${props.labelAfterInput.length}ch + 17px)`;
  }

  if (props.labelBeforeInput) {
    style.paddingLeft = `calc(${props.labelBeforeInput.length}ch + 17px)`;
  }

  function onKeyDown(keyEvent: any) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
      onBlur(keyEvent);
    }
  }

  const fieldProps = {
    style: style,
    placeholder: placeholder || ' ',
    id: id || name,
    ...inputProps,
    value: field.value || field.value === 0 ? field.value : '',
    onChange: onChange,
    onWheel: (e: React.WheelEvent) => e.target instanceof HTMLElement && e.target.blur(),
    onBlur: onBlur,
    onKeyPress: onKeyDown,
  };

  const errorClass = getInputErrorClass(name, meta, ignoreRequireTouched, getFieldIssues);

  return (
    <div
      ref={rsInputComponentRef}
      className={classNames('rs-field rs-input', errorClass, {
        [rsFieldDisabledClass]: props.disabled,
      })}
    >
      <div className="rs-field-content">
        {props.label && <RsLabel {...props} />}

        <div
          className={classNames('field-wrapper', `mode-${mode}`, {
            loading,
            'with-right-label': props.labelAfterInput,
            'required-input': props.required,
            'full-width': props.fullWidth,
          })}
        >
          {loading || formLoading ? (
            <RsLoading height="34px" />
          ) : (
            <>
              {props.icon && (
                <span className="icon">
                  <i className={`${props.icon}`}></i>
                </span>
              )}

              {mode === 'edit' ? fastField ? <FastField {...fieldProps} /> : <Field {...fieldProps} /> : ''}

              {amountFormatter && mode === 'edit' && (
                <div
                  className="input-number-format-overlay"
                  style={{ padding: `10px ${style.paddingRight ?? '10px'} 10px ${style.paddingLeft ?? '10px'}` }}
                  onClick={() => {
                    if (rsInputComponentRef && rsInputComponentRef.current) {
                      const fieldset = rsInputComponentRef.current.closest('fieldset');

                      if (fieldset) {
                        const disabledAttr = fieldset.getAttribute('disabled');

                        if (disabledAttr !== undefined && disabledAttr !== null) {
                          return;
                        }
                      }
                    }
                    if (props.disabled) return;
                    setAmountFormatter(false);
                    setListeningToEvents(true);
                  }}
                >
                  <p>{formatInput(field.value, format, type)}</p>
                </div>
              )}

              {props.showResetButton && mode === 'edit' && (
                <span className="icon reset-button" onClick={props.onResetClicked}>
                  <i>X</i>
                </span>
              )}

              {props.labelAfterInput && mode === 'edit' && (
                <span className="label-after-input">{props.labelAfterInput}</span>
              )}
              {props.labelBeforeInput && mode === 'edit' && (
                <span className="label-before-input">{props.labelBeforeInput}</span>
              )}

              {props.children && mode === 'edit' ? (
                <RsInfo id={`${props.name}_${Math.random()}`} type="dark">
                  {props.children}
                </RsInfo>
              ) : (
                ''
              )}

              {mode === 'view' &&
                (field.value !== undefined && field.value !== '' ? (
                  <p className="has-text-weight-bold rs-no-margin">
                    <span>{isNumber(field.value) ? formatInput(field.value, format, type) : field.value}</span>
                    {props.labelAfterInput && <span> {props.labelAfterInput}</span>}
                  </p>
                ) : formLoading ? (
                  <RsLoading height="22px" />
                ) : (
                  <p className="has-text-weight-bold rs-no-margin">-</p>
                ))}
            </>
          )}
        </div>
      </div>

      {!hideError && mode === 'edit' && <RsFieldError name={name} />}
      {showValidatingText && isValidating && (
        <span style={{ textAlign: 'right', width: '100%', fontSize: '0.6rem', color: '#5a709a' }}>VALIDATING...</span>
      )}
    </div>
  );
}

type InputFormatType = 'currency' | 'percentage' | 'integer';

const defaultFormatFromLabel = (labelAfterInput: string | undefined): InputFormatType | undefined => {
  if (!labelAfterInput) return undefined;
  const trimmed = labelAfterInput.trim();
  switch (trimmed) {
    case '%':
      return 'percentage';
    case 'year':
    case 'days':
    case 'business days':
      return 'integer';
    default:
      if (Currency.some((x) => trimmed.toLowerCase() === x.value.toString().toLowerCase())) {
        return 'currency';
      }
      return undefined;
  }
};

function formatInput(amount: string | number, format: InputFormatType | undefined, type: string | undefined): string {
  amount = isNumber(amount) ? `${amount}` : amount;
  if (!amount || amount === '') return '';
  if (!format && type !== 'number') return amount;
  const formatter = !format
    ? numberFormatter.float // due to the early return above, we know that here type === number
    : matchString(format, {
        currency: () => numberFormatter.currency,
        percentage: () => numberFormatter.percentage.full,
        integer: () => numberFormatter.integer,
      });
  return formatter.format(+amount);
}
