import * as Yup from 'yup';
import { defineApi } from '../common/api-definition';
import { ValidateCommandsErrorType } from '../loan/command-validation-error.type';
import { StructuredLoanCommandType } from '../loan/CommandType';
import { ObjectStripUnknown } from '../validation/atoms';
import { SLMigrateStructuredLoanEvent } from './SLEventType';

export type MigrateCovenantsRequest = {
  mode: 'simulation' | 'migration';
};

export type MigrateCovenantsResponse = {
  slEvents: { [loanId: string]: SLMigrateStructuredLoanEvent };
  lceCommands: { [loanId: string]: StructuredLoanCommandType[] };
  lceValidation: { [loanId: string]: ValidateCommandsErrorType };
  warnings: any[];
  messages: string[];
};

export const migrateCovenantsRequestValidation = ObjectStripUnknown({
  mode: Yup.mixed().oneOf(['simulation', 'migration']).required(),
}).required();

export const migrateCovenantsApi = defineApi<{
  body: MigrateCovenantsRequest;
  response: MigrateCovenantsResponse;
}>({
  url: 'platform-admin/migrate-covenants',
  method: 'post',
});
