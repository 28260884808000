import { ExternalIds } from '../common/ExternalIds';
import { SLPlatformInteractionsType } from '../common/PlatformInteractionsType';
import { SuccessOrErrorType } from '../common/SuccessOrErrorType';
import { InformationUndertakingSpecDraftType } from '../covenants/InformationUndertakingSpecType';
import { LoanMetricDraftType } from '../covenants/LoanMetricType';
import { SLReportingEventConfigDraft } from '../covenants/ReportingEventConfigType';
import { SLBookingModel } from '../loan/BookingModelType';
import { StructuredLoanValidationIssue } from '../loan/StructuredLoanValidationIssue';
import { SLPropertyDetails, SLPropertyType } from './SLPropertyType';

import {
  SLDocumentCategoryType,
  SLFeesType,
  StructuredAgreementType,
  StructuredConstructionType,
  StructuredFacilityType,
  StructuredTranchesType,
  StructuredUtilisationType,
} from './structured-agreement-type';
import { StructuredLoanState } from './structured-loan-state';
import {
  ScheduleConfigurationType,
  StructuredStartConfigurationType,
  StructuredStartDrawdownType,
  StructuredStartUtilisationType,
} from './structured-start-configuration-type';

export type RunEventReturnType = string | SuccessOrErrorType<true, StructuredLoanValidationIssue | string | undefined>;

export type SLEventType =
  | SLAddMetricEventType
  | SLRemoveMetricEventType
  | SLAddInformationUndertakingEventType
  | SLRemoveInformationUndertakingEventType
  | SLAddReportingEventEventType
  | SLRemoveReportingEventEventType
  | SLFacilityIntroUpdateEventType
  | SLSequenceUtilisationUpdateEventType
  | SLSequenceUtilisationAmendEvent
  | SLSequenceTermSheetUpdateEventType
  | SLSequenceTermSheetAmendEvent
  | SLSequenceConstructionSheetUpdateEventType
  | SLSequenceConstructionSheetAmendEvent
  | SLStartDrawdownUpdateEventType
  | SLStartUtilisationUpdateEventType
  | SLStartBookingModelUpdateEventType
  | SLStartBookingModelAmendEvent
  | SLExternalIDsEventType
  | SLStructureUpdateEventType
  | SLStructureAmendEvent
  | SLAgreementPartiesUpdateEventType
  | SLAgreementReportingEventUpdateEventType
  | SLAgreementInformationUndertakingUpdateEventType
  | SLAgreementPropertyUpdateEventType
  | SLAgreementMetricUpdateEvent
  | SLAgreementMetricAmendEvent
  | SLRunLoanEventType
  | SLUpdateState
  | SLUpdateCustomPlatformInteractionsEventType
  | SLUpdateLoanFees
  | SLUpdateLoanDocuments
  | SLStartLoanAmendEventType
  | SLCancelLoanAmendEventType
  | SLCommitLoanAmendEventType
  | SLExternalIDsAmendEventType
  | SLAgreementPartiesAmendEventType
  | SLAgreementPropertyAmendEventType
  | SLAmendLoanDocuments
  | SLFacilityIntroAmendEventType
  | SLAmendCustomPlatformInteractionsEventType
  | SLStartUtilisationAmendEvent
  | SLStartDrawdownAmendEvent
  | SLMigrateStructuredLoanEvent;

export type SLNewEventType = {
  type: 'new';
  agreement: Pick<
    StructuredAgreementType,
    'loan_name' | 'quantum_currency' | 'borrower_company_id' | 'agent' | 'external_id' | 'owner_company_id'
  >;
  facilities: {
    properties: Pick<SLPropertyType, 'name'>[];
    sequences: {
      utilisations?: {}[];
      construction?: {};
      tranches: {}[];
    }[];
  }[];
};

export type SLUpdateLoanDocuments = {
  type: 'loan-documents-update';
  loanId: string;
  version: number;
  documentCategories: SLDocumentCategoryType[];
};

export type SLUpdateLoanFees = {
  type: 'loan-fees-update';
  loanId: string;
  version: number;
  fees: SLFeesType;
};

export type SLAddMetricEventType = {
  type: 'add-metric-update';
  loanId: string;
  version: number;
  metrics?: {
    metricSelected: LoanMetricDraftType;
    metricFormulaLeft?: LoanMetricDraftType;
    metricFormulaRight?: LoanMetricDraftType;
  };
};

export type SLRemoveMetricEventType = {
  type: 'remove-metric-update';
  metricId: string;
  loanId: string;
  version: number;
};

export type SLAddInformationUndertakingEventType = {
  type: 'add-information-undertaking-update';
  loanId: string;
  version: number;
};

export type SLRemoveInformationUndertakingEventType = {
  type: 'remove-information-undertaking-update';
  informationUndertakingId: string;
  loanId: string;
  version: number;
};

export type SLAddReportingEventEventType = {
  type: 'add-reporting-event-update';
  loanId: string;
  version: number;
};
export type SLRemoveReportingEventEventType = {
  type: 'remove-reporting-event-update';
  loanId: string;
  reportingEventId: string;
  version: number;
};

export type SLUpdateCustomPlatformInteractionsParams = {
  loanId: string;
};

export type SLUpdateCustomPlatformInteractionsEventType = {
  type: 'custom-notifications-update';
  loanId: string;
  version: number;
  platformInteractions?: { [companyId: string]: Partial<SLPlatformInteractionsType> };
};

export type SLAgreementPartiesUpdateEventType = {
  type: 'agreement-parties-update';
  loanId: string;
  version: number;
  agreement: Pick<
    StructuredAgreementType,
    | 'loan_name'
    | 'quantum_currency'
    | 'agreement_date'
    | 'purpose'
    | 'applicable_law'
    | 'external_id'
    | 'borrower_company_id'
    | 'owner_company_id'
    | 'agent'
    | 'guarantor_name'
    | 'legal_entity'
    | 'cap_table'
    | 'responsible_user_ids'
  >;
};

export type SLFacilityIntroUpdateEventType = {
  type: 'facility-intro-update';
  loanId: string;
  facilityId: string;
  version: number;
  facility: Pick<StructuredFacilityType, 'legal_entity'>;
};

export type SLSequenceUtilisationUpdateEventType = {
  type: 'sequence-utilisation-update';
  loanId: string;
  facilityId: string;
  sequenceId: string;
  utilisationId: string;
  version: number;
  utilisation: Pick<
    StructuredUtilisationType,
    | 'quantum_availability_period'
    | 'start_quantum_availability_period'
    | 'commitment'
    | 'quantum_utilisation_request_time'
    | 'quantum_utilisation_request_days'
  >;
};

export type SLSequenceUtilisationAmendEvent = {
  type: 'sequence-utilisation-amend';
  loanId: string;
  facilityId: string;
  sequenceId: string;
  utilisationId: string;
  version: number;
  utilisation: SLSequenceUtilisationUpdateEventType['utilisation'];
};

export type SLSequenceTermSheetUpdateEventType = {
  type: 'sequence-term-sheet-update';
  loanId: string;
  facilityId: string;
  sequenceId: string;
  termsId: string;
  version: number;
  terms: Omit<StructuredTranchesType, 'id' | 'name'>;
};

export type SLSequenceTermSheetAmendEvent = {
  type: 'sequence-term-sheet-amend';
  loanId: string;
  facilityId: string;
  sequenceId: string;
  termsId: string;
  version: number;
  terms: Omit<StructuredTranchesType, 'id' | 'name'>;
};

export type SLSequenceConstructionSheetUpdateEventType = {
  type: 'sequence-construction-sheet-update';
  loanId: string;
  facilityId: string;
  sequenceId: string;
  constructionId: string;
  version: number;
  constructionValues: Omit<StructuredConstructionType, 'id' | 'name'>;
};

export type SLSequenceConstructionSheetAmendEvent = {
  type: 'sequence-construction-sheet-amend';
  loanId: string;
  facilityId: string;
  sequenceId: string;
  constructionId: string;
  version: number;
  constructionValues: Omit<StructuredConstructionType, 'id' | 'name'>;
};

export type SLStartUtilisationUpdateEventType = {
  type: 'start-utilisation-update';
  loanId: string;
  facilityId: string;
  sequenceId: string;
  version: number;
  utilisation: StructuredStartUtilisationType;
  scheduleConfigurations: ScheduleConfigurationType;
};

export type SLStartUtilisationAmendEvent = Omit<SLStartUtilisationUpdateEventType, 'type'> & {
  type: 'start-utilisation-amend';
};

export type SLStartDrawdownUpdateEventType = {
  type: 'start-drawdown-update';
  loanId: string;
  facilityId: string;
  sequenceId: string;
  version: number;
  drawdown: StructuredStartDrawdownType;
  scheduleConfigurations: ScheduleConfigurationType;
  constructionConfigurations: ScheduleConfigurationType;
};

export type SLExternalIDsEventType = {
  type: 'external-ids-update';
  loanId: string;
  version: number;
  externalIds: ExternalIds;
};

export type SLStartBookingModelUpdateEventType = {
  type: 'start-booking-model-update';
  loanId: string;
  facilityId: string;
  version: number;
  booking_model: SLBookingModel;
};

export type SLStartBookingModelAmendEvent = {
  type: 'start-booking-model-amend';
  loanId: string;
  facilityId: string;
  version: number;
  booking_model: SLBookingModel;
};

export type SLStructureUpdateFacility = {
  id?: string;
  name: string;
  properties: SLStructureUpdateProperty[];
  sequences: SLStructureUpdateSequence[];
};

export type SLStructureUpdateProperty = {
  id?: string;
  name: string;
};

export type SLStructureUpdateSequence = {
  id?: string;
  name: string;
  construction?: {}; // Set for construction tranches.
};

export type SLStructureUpdateEventType = {
  type: 'loan-structure-update';
  loanId: string;
  version: number;
  facilities: SLStructureUpdateFacility[];
};

export type SLStructureAmendEvent = {
  type: 'loan-structure-amend';
  loanId: string;
  version: number;
  facilities: SLStructureUpdateFacility[];
};

export type SLAgreementMetricUpdateEvent = {
  type: 'agreement-metric-update';
  loanId: string;
  metricId: string;
  version: number;
  metric: Omit<LoanMetricDraftType, 'id'>;
};

export type SLAgreementMetricAmendEvent = {
  type: 'agreement-metric-amend';
  loanId: string;
  metricId: string;
  version: number;
  metric: Omit<LoanMetricDraftType, 'id'>;
};

export type SLAgreementInformationUndertakingUpdateEventType = {
  type: 'agreement-information-undertaking-update';
  loanId: string;
  iuId: string;
  version: number;
  information_undertaking: Omit<InformationUndertakingSpecDraftType, 'id'>;
};

export type SLAgreementReportingEventUpdateEventType = {
  type: 'agreement-reporting-event-update';
  loanId: string;
  reId: string;
  version: number;
  reporting_event: Omit<SLReportingEventConfigDraft, 'id'>;
};

export type SLRunLoanEventType = {
  type: 'run-structured-loan-update';
  loanId: string;
  version: number;
};

export type SLAgreementPropertyUpdateEventType = {
  type: 'agreement-property-update';
  loanId: string;
  facilityId: string;
  propertyId: string;
  version: number;
  property: Partial<SLPropertyDetails>;
};

export type SLUpdateState = {
  type: 'state-update';
  loanId: string;
  version: number;
  state: StructuredLoanState;
};

export type SLImportEventType = {
  type: 'import-loan';
  schema: string;
  loan: StructuredAgreementType;
  startConfiguration: StructuredStartConfigurationType;
  skipValidation: boolean;
};

export type SLExportEventType = {
  type: 'export-loan';
  loanId: string;
};

// ***************** AMEND EVENT TYPES *****************

export enum AmendEventType {
  START_LOAN_AMEND = 'start-loan-amend',
  CANCEL_LOAN_AMEND = 'cancel-loan-amend',
  AMEND_EXTERNAL_IDS = 'external-ids-amend',
}

export type SLStartLoanAmendEventType = {
  type: 'start-loan-amend';
  loanId: string;
  version: number;
};

export type SLCancelLoanAmendEventType = {
  type: 'cancel-loan-amend';
  loanId: string;
  version: number;
};

export type SLCommitLoanAmendEventType = {
  type: 'commit-loan-amend';
  loanId: string;
  version: number;
};

export type SLExternalIDsAmendEventType = {
  type: 'external-ids-amend';
  loanId: string;
  version: number;
  externalIds: ExternalIds;
};

export type SLAgreementPartiesAmendEventType = Omit<SLAgreementPartiesUpdateEventType, 'type'> & {
  type: 'agreement-parties-amend';
};

export type SLAgreementPropertyAmendEventType = Omit<SLAgreementPropertyUpdateEventType, 'type'> & {
  type: 'agreement-property-amend';
};

export type SLAmendLoanDocuments = Omit<SLUpdateLoanDocuments, 'type'> & {
  type: 'loan-documents-amend';
};

export type SLFacilityIntroAmendEventType = Omit<SLFacilityIntroUpdateEventType, 'type'> & {
  type: 'facility-intro-amend';
};

export type SLAmendCustomPlatformInteractionsEventType = Omit<SLUpdateCustomPlatformInteractionsEventType, 'type'> & {
  type: 'custom-notifications-amend';
};

export type SLStartDrawdownAmendEvent = Omit<SLStartDrawdownUpdateEventType, 'type'> & {
  type: 'start-drawdown-amend';
};

/** Replacement of a loan done through a migrations. */
export type SLMigrateStructuredLoanEvent = {
  type: 'migrate-structured-loan';
  loanId: string;
  version: number;

  /** The migrated loan that replaces the existing loan. */
  loan: StructuredAgreementType;
};
