/** Types for specifying reporting events */

import { InterestPeriodType } from '../loan/InterestPeriodType';
import {
  InformationUndertakingQualificationType,
  InformationUndertakingScheduleDraftType,
  InformationUndertakingScheduleType,
} from './InformationUndertakingSpecType';
import { REReportedAgainst, REReportedAgainstDraft } from './REReportedAgainstType';

export interface ReportingEventRequiredDocument {
  id: string;
  name: string;
}

export const reportingEventCollectionIntervals = [
  '12_months_rolling',
  '6_months_rolling',
  '3_months_rolling',
  'current_period',
  'latest_available',
] as const;

export type ReportingEventCollectionIntervalType = (typeof reportingEventCollectionIntervals)[number];

const reportingEventCollectionIntervalLabels = {
  '12_months_rolling': '12 months rolling',
  '6_months_rolling': '6 months rolling',
  '3_months_rolling': '3 months rolling',
  current_period: 'Current period',
  latest_available: 'Latest available',
};

export const prettyCollectionInterval = (x: ReportingEventCollectionIntervalType) =>
  reportingEventCollectionIntervalLabels[x];

export type SLReportingEventConfig = {
  id: string;
  name: string;
  ccTemplate: string;
  qualification?: InformationUndertakingQualificationType;
  schedule: InformationUndertakingScheduleType;
  deadline?: number;
  collections: SLReportingEventCollectionConfig[];
  documents?: ReportingEventRequiredDocument[];
};

export type SLReportingEventConfigDraft = {
  id: string;
  name: string;
  ccTemplate?: string;
  qualification?: InformationUndertakingQualificationType;
  schedule: InformationUndertakingScheduleDraftType;
  deadline?: number;
  collections?: SLReportingEventCollectionConfigDraft[];
  documents?: ReportingEventRequiredDocument[];
};

export type SLReportingEventCollectionConfig = {
  metricId: string;
  qualification: InformationUndertakingQualificationType;
  interval: ReportingEventCollectionIntervalType;
  reportedAgainst: REReportedAgainst;
  recurrence: InterestPeriodType;
};

export type SLReportingEventCollectionConfigDraft = {
  metricId?: string;
  qualification?: InformationUndertakingQualificationType;
  interval?: ReportingEventCollectionIntervalType;
  reportedAgainst?: REReportedAgainstDraft;
  recurrence?: InterestPeriodType;
};
