import RsList from '../../../../components/rs-elements/rs-list/RsList';
import { RsListItem } from '../../../../components/rs-elements/rs-list/RsListItem';
import './HomepageWhatWeDoComponent.scss';

export default function HomepageWhatWeDoComponent() {
  const items: RsListItem[] = [
    new RsListItem('Manage your loan portfolio through its whole lifecycle in one place'),
    new RsListItem('Import your existing loan portfolio to benefit from all digital services'),
    new RsListItem('Discover a new way to request CRE loans and negotiate them fully digitally'),
  ];

  return (
    <div className="homepage-what-we-do-component">
      <h4 className="has-text-centered headline-underlined has-text-weight-bold is-uppercase">What we do</h4>

      <div className="container">
        <div className="columns is-multiline is-centered">
          <div className="column video-container is-12-tablet is-6-desktop">
            <iframe
              src="https://player.vimeo.com/video/1003188471?title=0&amp;speed=0&amp;pip=0&amp;quality=undefined&amp;app_id=122963&amp;texttrack=undefined"
              width="500"
              height="281"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              title="Realstocks-Platform-Demo-Aug-2022"
              data-ready="true"
            />
          </div>
          <div className="column list-container is-12-mobile is-6-desktop">
            <RsList items={items} />
          </div>
        </div>
      </div>
    </div>
  );
}
