import { AmortisationCustomType } from './AmortisationType';
import { BaseRateType } from './BaseRateType';
import { AvailableBookingStatusType, BookingType } from './BookingType';
import { CastVoteType } from './CastVoteType';
import { LateTransactionCommandType, SetAmortisationCommand, SetInterestRateCommand } from './CommandType';
import { Drawdown } from './Drawdown';
import { InitiateDrawdownDetails } from './InitiateDrawdownDetails';
import { InitiateSubperiodPrepayment } from './InitiateSubperiodPrepayment';
import { InitiateTerminationDetails } from './InitiateTerminationDetails';
import { InitiateUtilisationDetails } from './InitiateUtilisationDetails';
import { CustomInterestRateType, InterestRateType } from './InterestRateType';
import { IntervalDetailsType } from './IntervalDetailsType';
import { IUCollectionDetails } from './IUCollectionDetails';
import { LateTransactionSpecType } from './LateTransactionSpecType';
import {
  LCECovenant,
  LCECovenantCollection,
  LCECovenantInterval,
  LCEMetric,
  LCEMetricCollection,
} from './lce-covenants';
import { LifecycleCovenantReportType } from './LifecycleCovenantReportType';
import { LifecycleInformationUndertakingType } from './LifecycleInformationUndertakingType';
import { MortgageBondCoupon } from './MortgageBondCoupon';
import { MortgageBondCouponDetails } from './MortgageBondCouponDetails';
import { RECollectionDetails } from './RECollectionDetails';
import { Termination } from './Termination';
import { TransactionType } from './TransactionType';
import { Utilisation } from './Utilisation';

export type NextCommandType = SimpleLoanNextCommandType | StructuredLoanNextCommand;

export type NextCommandTypeType =
  | NextCommandType['type']
  | SequenceNextCommandType['next-command']['type']
  | TransactionNextCommandType['transaction']['type'];

export type SimpleLoanNextCommandType =
  | TransactionNextCommandType
  | BaseRateNextCommandType
  | MortgageBondCouponNextCommand
  | InitiateDrawdownNextCommand
  | InitiateUtilisationNextCommand
  | InitiateTerminationNextCommand
  | InitiateSubperiodPrepaymentNextCommand
  | SetAmortisationNextCommand
  | SetInterestRateNextCommand
  | CastVoteNextCommandType;

export type StructuredLoanNextCommand =
  | AmendStructuredLoanNextCommand
  | SequenceNextCommandType
  | SLInformationUndertakingNextCommandType
  | SLCovenantReportNextCommandType
  | MetricCollectionNextCommand
  | CovenantCollectionNextCommand
  | ReopenMetricCheckDateNextCommand;

export type INextCommand = {
  'loan-id': string;
  date: string;
};

type INextCommandNoDueDate = Omit<INextCommand, 'date'> & { date: undefined };
type INextCommandOptionalDueDate = Omit<INextCommand, 'date'> & { date?: string };

// Next command for the sequence level of a loan agreement.
export type SequenceNextCommandType<T = SimpleLoanNextCommandType> = Omit<INextCommand, 'date'> & { date?: string } & {
  type: 'sequence';
  'facility-id': string;
  'sequence-id': string;

  // The ID of the terms tranche that the command is for. The ID is set only if
  // the 'id' attribute has been set for the terms.
  'tranche-id'?: string;

  // The index of the terms tranche that the command is for. The tranches are numbered 0, 1, ...
  'tranche-index': number;

  'next-command': T;
};

export type AmendStructuredLoanNextCommand = INextCommandNoDueDate & {
  type: 'amend-structured-loan';
  'tranche-amendments': {
    [termsId: string]: AvailableTrancheAmendment;
  };
};

export type AvailableTrancheAmendment = {
  // True if the maturity of the tranche can be amended.
  'maturity-payment-of-debt'?: true;

  'commitment-availability-start'?: true;

  'interest-rate'?: {
    // The earliest period for which the interest rate can be changed
    'first-outstanding-period-index': number;

    // The number of interest periods (if known)
    'number-of-periods'?: number;

    // The current interest rate settings
    'interest-rate': InterestRateType;
  };
};

export type TransactionNextCommandType = INextCommand & {
  type: 'transaction';
  transaction: TransactionType;
  commands: { [bookingId: string]: BookingNextCommandType };
  'late-transaction-command'?: {
    type: 'late-transaction';
    // The late transaction configuration (if it exists).
    'late-transaction'?: LateTransactionSpecType;
    command: Omit<LateTransactionCommandType['command'], 'value-date'>;
  };
};

export type BookingNextCommandType = INextCommand & {
  type: 'booking';
  booking: BookingType;
  command: Omit<BookingType, 'status' | 'details'> & { status: AvailableBookingStatusType };
};

export type BaseRateNextCommandType = INextCommand & {
  type: 'base-rate';
  'base-rate': Omit<BaseRateType, 'rate'>;
  command: Omit<BaseRateType, 'rate' | 'details'>;
};

export type MortgageBondCouponNextCommand = INextCommand & {
  type: 'mortgage-bond-coupon';
  command: MortgageBondCoupon;
  details: MortgageBondCouponDetails;
};

export type InitiateDrawdownNextCommand = INextCommandNoDueDate & {
  type: 'initiate-drawdown';
  command: Omit<Drawdown, 'amount' | 'value-date'>;
  details: InitiateDrawdownDetails;
};

export type InitiateUtilisationNextCommand = INextCommandOptionalDueDate & {
  type: 'initiate-utilisation';
  command: Omit<Utilisation, 'loan-size' | 'utilisation-date'>;
  details: InitiateUtilisationDetails;
};

export type InitiateTerminationNextCommand = INextCommandNoDueDate & {
  type: 'initiate-termination';
  command: Omit<Termination, 'value-date'>;
  details: InitiateTerminationDetails;
};

export type InitiateSubperiodPrepaymentNextCommand = INextCommandNoDueDate & {
  type: 'initiate-subperiod-prepayment';
  command: Omit<InitiateSubperiodPrepayment, 'value-date' | 'amortisation-amount' | 'fee-amount'>;

  // The value date of the prepayment must be greater than or equal to this date.
  'min-value-date': string;

  // The value date of the prepayment must be less than or equal to this date.
  'max-value-date': string;
};

export type SetInterestRateNextCommand = INextCommandNoDueDate & {
  type: 'set-interest-rate';
  command: Omit<SetInterestRateCommand['command'], 'interest-rate'>;

  // The earliest period for which the interest rate can be changed
  'first-outstanding-period-index': number;

  // The number of interest periods
  'number-of-periods': number;

  // The current interest rate settings
  'interest-rate': CustomInterestRateType;
};

export type SetAmortisationNextCommand = INextCommandNoDueDate & {
  type: 'set-amortisation';
  command: Omit<SetAmortisationCommand['command'], 'amortisation'>;

  // The earliest period for which the amortisation can be changed
  'first-outstanding-period-index': number;

  // The number of interest periods
  'number-of-periods': number;

  // The current amortisation settings
  amortisation: AmortisationCustomType;
};

export type CastVoteNextCommandType = INextCommand & {
  type: 'cast-vote';
  command: Omit<CastVoteType, 'choice'>;
};

export type MetricCollectionNextCommand = INextCommand & {
  type: 'metric-collection';

  /**
     'open': one or more metric values are missing
     'reopened': all values have been submitted but the check date has been reopened
   */
  status: 'open' | 'reopened';

  'check-date': string;
  collections: LCEMetricCollection[];
};

export type CovenantCollectionNextCommand = INextCommand & {
  type: 'covenant-collection';
  metrics: LCEMetric[];
  covenant: LCECovenant;
  interval: LCECovenantInterval;
  collections: LCECovenantCollection[];
};

export type ReopenMetricCheckDateNextCommand = INextCommandNoDueDate & {
  type: 'reopen-metric-check-date';
  command: {
    'check-date': string;
  };
};

export type SLInformationUndertakingNextCommandType = INextCommand & {
  type: 'sl-information-undertaking';
  'information-undertaking': LifecycleInformationUndertakingType;
  'interval-details': IntervalDetailsType;
  'collection-details': IUCollectionDetails;
  command: {
    id: string;
    'check-date': string;
  };
};

export const SLCovenantReportNextCommandTypeValue = 'sl-covenant-report';

export type SLCovenantReportNextCommandType = INextCommand & {
  type: 'sl-covenant-report';
  'covenant-report': LifecycleCovenantReportType;
  'interval-details': IntervalDetailsType;
  'collection-details': RECollectionDetails;
  command: {
    id: string;
    'check-date': string;
  };
};

export const AllNextCommandLabels: Record<NextCommandType['type'], string> = {
  'amend-structured-loan': 'Amend Structured Loan',
  transaction: 'Transaction',
  'base-rate': 'Set Base Rate',
  'mortgage-bond-coupon': 'Set Custom Interest',
  'initiate-drawdown': 'Initiate Drawdown',
  'initiate-utilisation': 'Utilisation Request',
  'initiate-termination': 'Initiate Termination',
  'initiate-subperiod-prepayment': 'Initiate Prepayment',
  'metric-collection': 'Collection Event',
  'covenant-collection': 'Covenant Report',
  'reopen-metric-check-date': 'Reopen Collection Event',
  'sl-information-undertaking': 'Collection Event',
  'sl-covenant-report': 'Covenant Report',
  'cast-vote': 'Cast Vote',
  sequence: 'Structured Loan Command',
  'set-interest-rate': 'Set Interest Rate',
  'set-amortisation': 'Set Amortisation',
};
