import { numberLocales } from '../config/number.config';

const NumberFormatterTwoDecimals = Intl.NumberFormat(numberLocales, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const NumberFormatterFull = Intl.NumberFormat(numberLocales, {
  minimumFractionDigits: 0,
  maximumFractionDigits: 10,
});

const NumberFormatterInteger = Intl.NumberFormat(numberLocales, {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

/**
 * By default, percentages in the system should have 3 decimals.
 */
const PercentageFormatter = Intl.NumberFormat(numberLocales, {
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
});

export const numberFormatter = {
  amount: {
    twoDecimals: NumberFormatterTwoDecimals,
    full: NumberFormatterFull,
  },
  percentage: {
    twoDecimals: NumberFormatterTwoDecimals,
    threeDecimals: PercentageFormatter,
    full: NumberFormatterFull,
  },
  ratio: {
    maxThreeDecimals: Intl.NumberFormat(numberLocales, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 3,
      style: 'percent',
    }),
  },
  integer: NumberFormatterInteger,
  float: NumberFormatterTwoDecimals,
  currency: NumberFormatterTwoDecimals,
  compact: Intl.NumberFormat(numberLocales, { notation: 'compact' }),
};
