import { defineApi } from '../common/api-definition';
import { SLEventsMap } from '../loan/EventType';
import { ObjectStripUnknown, RequiredArray } from '../validation/atoms';
import { NonEmptyString } from '../validation/base';

export type LoggedEventsRequest = {
  loanIds: string[];
};

export const loggedEventsRequestValidation = ObjectStripUnknown({
  loanIds: RequiredArray(NonEmptyString.required()),
}).required();

export type LoggedEventsResponse = {
  events: SLEventsMap;
};

export const loggedEventsApi = defineApi<{
  body: LoggedEventsRequest;
  response: LoggedEventsResponse;
}>({
  url: 'platform-admin/logged-events',
  method: 'post',
});
