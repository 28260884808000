import * as Yup from 'yup';
import { defineApi } from '../common/api-definition';
import { StructuredLoanState } from '../structured-loan/structured-loan-state';
import { ObjectStripUnknown, RequiredDate } from '../validation/atoms';
import { literal, sumTypeRequired } from '../validation/base';
import { EventViewRequestValidation } from '../validation/EventViewRequestValidation';
import { EventViewRequest } from './EventViewRequest';
import { LCEMetricCollection } from './lce-covenants';
import { ReopenMetricCheckDateNextCommand } from './NextCommandType';

export type MetricCollectionViewRequest =
  | {
      type: 'next-command-or-event';
      loanId: string;
      checkDate: string;
    }
  | EventViewRequest;

export const metricCollectionViewRequestValidation = sumTypeRequired({
  'next-command-or-event': ObjectStripUnknown({
    type: literal('next-command-or-event'),
    loanId: Yup.string().required(),
    checkDate: RequiredDate(),
  }),
  event: EventViewRequestValidation,
});

export type MetricCollectionView = NextMetricCollectionView | PastMetricCollectionView;

export type NextMetricCollectionView = CommonMetricCollectionView & {
  type: 'next';
  status: 'open' | 'reopened';
};

export type PastMetricCollectionView = CommonMetricCollectionView & {
  type: 'past';
  reopen?: ReopenMetricCheckDateNextCommand;
};

export type CommonMetricCollectionView = {
  loanId: string;
  checkDate: string;
  dueDate?: string;
  version: number;
  state: StructuredLoanState;
  currency: string;
  facilities: {
    [facilityId: string]: {
      name: string;
      legalEntityName?: string;
      externalId: string | undefined;
      registrationNumber: string | undefined;
    };
  };
  properties: { [propertyId: string]: { name?: string; externalId: string | undefined } };
  fund: { name?: string; externalId?: string; registrationNumber?: string };
  collections: LCEMetricCollection[];
};

export const metricCollectionViewApi = defineApi<{
  body: MetricCollectionViewRequest;
  response: MetricCollectionView;
}>({
  url: 'structured-loans/metric-collection-view',
  method: 'post',
});
