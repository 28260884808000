import clsx from 'clsx';
import { Field, FieldAttributes, useField } from 'formik';
import { Fragment, useContext } from 'react';
import RsInfo from '../../rs-elements/rs-info/RsInfo';
import RsLabel from '../rs-label/RsLabel';
import RsFieldError from '../RsFieldError';
import { RsFormContext } from '../RsForm';
import './RsCheckbox.scss';

type Props = {
  id: string;
  icon: string;
  name: string;
  children: string;
  info?: string;
  label?: string;
  className?: string;
  fullWidth?: boolean;
  onChange?: (e: any) => void;
  hideEmptyLabelContainer?: boolean;
  align?: 'left' | 'center' | 'right';
  boxClassName?: string;
} & FieldAttributes<any>;

const CheckboxLabel = (props: Props) => {
  const { hideEmptyLabelContainer, label, className } = props;

  if (!label) {
    return hideEmptyLabelContainer ? null : <div className="no-label-container" />;
  }

  return <RsLabel {...props} />;
};

export default function RsCheckbox(props: Props) {
  const { fullWidth, align, className } = props;
  const [field, meta] = useField(props);
  const { mode } = useContext(RsFormContext);
  const isChecked = field.value === true || false;

  const onChange = (e: any) => {
    if (props.onChange) props.onChange(e.target.checked);
    field.onChange(e);
  };

  return (
    <Fragment>
      <div
        className={`rs-field rs-checkbox-container ${className || ''} ${fullWidth ? 'is-fullwidth' : ''} ${
          meta.error ? 'has-error' : ''
        }`}
      >
        <div className={`rs-field-content${align ? ` is-justify-content-${align}` : ''}`}>
          <CheckboxLabel {...props} />

          <div className={`field-wrapper mode-${mode}${align ? ` is-flex is-justify-content-${align}` : ''}`}>
            {mode === 'edit' && (
              <>
                <div className={`rs-checkbox-inner-field flex items-center justify-end relative`}>
                  <Field
                    id={props.id || props.name}
                    type="checkbox"
                    name={props.name}
                    value={field.value || ''}
                    onChange={onChange}
                    checked={isChecked}
                    disabled={props.disabled}
                    className="absolute inset-0 opacity-0 cursor-pointer"
                  />

                  <div
                    className={clsx('rs-checkmark relative', props.boxClassName, props.disabled && 'disabled')}
                  ></div>
                  {props.children || ''}
                </div>
                {props.info ? (
                  <RsInfo id={`${props.name}_${Math.random()}`} type="dark">
                    {props.info}
                  </RsInfo>
                ) : (
                  ''
                )}
              </>
            )}

            {mode === 'view' && (
              <Fragment>
                {isChecked && (
                  <div className="view-check is-flex is-flex-direction-row is-align-items-center">
                    <i className="fas fa-check-square"></i>
                  </div>
                )}

                {!isChecked && (
                  <div className="view-check is-flex is-flex-direction-row is-align-items-center">
                    <i className="fas fa-square"></i>
                  </div>
                )}
              </Fragment>
            )}
          </div>
        </div>

        {mode === 'edit' && <RsFieldError name={props.name} align={align} />}
      </div>
    </Fragment>
  );
}
