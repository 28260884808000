import { isNumber } from 'lodash';
import { InformationUndertakingSpecDraftType } from '../covenants/InformationUndertakingSpecType';
import { LoanMetricDraftType } from '../covenants/LoanMetricType';
import { SLReportingEventConfig, SLReportingEventConfigDraft } from '../covenants/ReportingEventConfigType';
import { NotFoundException } from '../utils/exceptions';
import { StructuredAgreementDbType } from './structured-agreement-db-type.type';
import {
  StructuredAgreementType,
  StructuredFacilityType,
  StructuredLegalEntityType,
  StructuredSequenceType,
  StructuredTranchesType,
} from './structured-agreement-type';
import { StructuredLoanState } from './structured-loan-state';

const getLoanByState = (loan: StructuredAgreementDbType): StructuredAgreementType => {
  if (loan.state === StructuredLoanState.AMEND) {
    const amendedLoan = loan.data.amend?.loan;
    if (!amendedLoan) throw new Error('No data for amended loan');
    return amendedLoan;
  }
  return loan.data.loan;
};

const getTotalCommitment = (loan: StructuredAgreementType): number | undefined => {
  const commitments = loan.facilities.map(facility => getFacilityTotalCommitment(facility));
  return commitments.filter(isNumber).reduce((acc, x) => (acc ?? 0) + x, undefined as number | undefined);
};

const getFacilityTotalCommitment = (facility: StructuredFacilityType): number | undefined => {
  const commitments = facility.sequences.flatMap(sequence => [
    sequence.construction?.commitment,
    sequence.utilisations?.[0].commitment,
  ]);
  return commitments.filter(isNumber).reduce((acc, x) => (acc ?? 0) + x, undefined as number | undefined);
};

const getCollectionEvents = (loan: StructuredAgreementType): InformationUndertakingSpecDraftType[] =>
  loan.information_undertakings;

function getMetric(loan: StructuredAgreementType, metricId: string): LoanMetricDraftType {
  const metric = loan.loan_metrics.find(m => m.id === metricId);
  if (!metric) {
    throw new NotFoundException('Metric not found');
  }
  return metric;
}

const findFacility = (loan: StructuredAgreementType, id: string): StructuredFacilityType | undefined =>
  loan.facilities.find(f => f.id === id);

function getFacility(loan: StructuredAgreementType, id: string): StructuredFacilityType {
  const facility = findFacility(loan, id);
  if (!facility) throw new NotFoundException(`Facility with id "${id}" not found`);
  return facility;
}

const findSequence = (facility: StructuredFacilityType, id: string): StructuredSequenceType | undefined =>
  facility.sequences.find(s => s.id === id);

function getSequence(facility: StructuredFacilityType, id: string): StructuredSequenceType {
  const sequence = findSequence(facility, id);
  if (!sequence) throw new NotFoundException(`Sequence with id "${id}" not found`);
  return sequence;
}

const findTranche = (sequence: StructuredSequenceType, id: string): StructuredTranchesType | undefined =>
  sequence.tranches.find(t => t.id === id);

function getTranche(sequence: StructuredSequenceType, id: string): StructuredTranchesType {
  const tranche = findTranche(sequence, id);
  if (!tranche) throw new NotFoundException(`Tranche with id "${id}" not found`);
  return tranche;
}

const getAgentId = (loanAgreement: StructuredAgreementType) => loanAgreement.agent;

const getBorrowerId = (loanAgreement: StructuredAgreementType) => loanAgreement.borrower_company_id;

/**
 * The lead borrower is an SPV that is created to mitigate loan risks.
 */
function getLeadBorrower(loan: StructuredAgreementType): {
  leadBorrower: StructuredLegalEntityType | undefined;
  isFund: boolean;
} {
  if (loan.legal_entity == null) {
    return { leadBorrower: undefined, isFund: false };
  }

  const entity = loan.legal_entity;

  // Legacy. If it doesn't have a tag, it's always a fund.
  if (entity.type == null || entity.type === 'fund') {
    return { leadBorrower: entity, isFund: true };
  }

  if (entity.type === 'facility-link') {
    return { leadBorrower: loan.facilities.find(f => f.id === entity.facility_id)?.legal_entity, isFund: false };
  }

  // Can't get here, but typescript thinks its possible
  return { leadBorrower: undefined, isFund: false };
}

const getFund = (loan: StructuredAgreementType): StructuredLegalEntityType | undefined => {
  const leadBorrower = getLeadBorrower(loan);
  return leadBorrower.isFund ? leadBorrower.leadBorrower : undefined;
};

const getReportingEvent = (
  loan: StructuredAgreementType,
  reId: string
): SLReportingEventConfigDraft | SLReportingEventConfig => {
  for (const re of loan.reporting_events) {
    if (re.id === reId) return re;
  }
  throw new NotFoundException(`Reporting event with id ${reId} not found`);
};

const lenderIds = (loan: StructuredAgreementType) => loan.cap_table.map(i => i.lender);

export const loanConfig = {
  getCollectionEvents,
  getFacility,
  getFacilityTotalCommitment,
  getFund,
  getLeadBorrower,
  getLoanByState,
  getMetric,
  getSequence,
  getTotalCommitment,
  getTranche,
  getAgentId,
  getBorrowerId,
  findFacility,
  findSequence,
  findTranche,
  getReportingEvent,
  lenderIds,
};
