import { isString } from 'lodash';
import React, { Fragment, useEffect } from 'react';
import DataTable, { IDataTableProps } from 'react-data-table-component';
import useDebounce from '../../effects/useDebounce';
import RsLoading from '../rs-loading/RsLoading';
import './RsTable.scss';

type SearchComponentPropsType = {
  searchText: string;
  onSearch: (e: any) => void;
  onClear: () => void;
};

function SearchComponent(props: SearchComponentPropsType) {
  const { searchText, onSearch, onClear } = props;
  const noSearch = searchText.length === 0;
  const randomId = Math.random();
  return (
    <div className="rs-table-filter-component is-flex is-align-items-center">
      <label htmlFor={`search-input-rs-table-${randomId}`}>Search</label>
      <div className="control has-icons-right">
        <input
          id={`search-input-rs-table-${randomId}`}
          className="input"
          type="text"
          aria-label="Table Search Input"
          value={searchText}
          onChange={onSearch}
        />
        <button
          className={'button is-text icon is-right is-clickable' + ' ' + (noSearch ? 'is-hidden' : '')}
          onClick={onClear}
          disabled={noSearch}
        >
          <i className="fas fa-times"></i>
        </button>
      </div>
    </div>
  );
}

const NoDataComponent = (props: { message: string }) => {
  return <div className="rs-table-no-data-component">{props.message}</div>;
};

interface RsTablePropsI<T> extends Omit<IDataTableProps<T & { action?: Function }>, 'data' | 'defaultSortAsc'> {
  loading?: boolean | string;

  /**
   * If set, a search will be displayed at the top of the table allowing the user to search on the data
   */
  searchByFields?: (keyof T)[];

  data?: (T & { action?: Function })[];
  clickableRows?: boolean;
  noPagination?: boolean;
  noDataMessage?: string;
  error?: Error;
  onRowClicked?: (object: T) => void;
  defaultSortColumnId?: string;
  defaultSortDirection?: 'asc' | 'desc';
  customPaginationRowsPerPageOptions?: number[];
  customPaginationRowsPerPage?: number;
  isServerPagination?: boolean;
  handleRowsPerPageChangeServer?: (perPage: number, page: number) => void;
  handlePageChangeServer?: (page: number, totalRows: number) => void;
  paginationTotalRowsServer?: number;
  isServerSort?: boolean;
  handleSortServer?: (column: any, sortDirection: any) => void;
  isServerSearch?: boolean;
  handleSearchServer?: (searchText: string) => void;
  loadingRowHeight?: string;
  subheaderComponent?: JSX.Element;
}

export default function RsTable<T>(props: RsTablePropsI<T>) {
  const {
    columns,
    title,
    data,
    loading,
    searchByFields,
    noDataMessage = 'No Data',
    clickableRows,
    noPagination,
    conditionalRowStyles,
    className,
    noTableHead,
    noHeader = false,
    expandableRowDisabled,
    expandableRows,
    expandableRowsComponent,
    error,
    onRowClicked,
    defaultSortColumnId,
    customPaginationRowsPerPage,
    customPaginationRowsPerPageOptions,
    isServerPagination = false,
    isServerSort = false,
    isServerSearch = false,
    paginationTotalRowsServer,
    handlePageChangeServer,
    handleRowsPerPageChangeServer,
    handleSortServer,
    handleSearchServer,
    defaultSortDirection,
    loadingRowHeight = '20.5px',
    subheaderComponent,
  } = props;
  const initialPerPage = 15;
  const [totalRows, setTotalRows] = React.useState(0);
  const additionalLoadingRows = React.useRef(0);
  const [filterText, setFilterText] = React.useState<string>('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [initialRender, setInitialRender] = React.useState(true);

  useDebounce(
    () => {
      if (initialRender) {
        setInitialRender(false);
        return;
      }
      if (isServerSearch && handleSearchServer) {
        handleSearchServer(filterText);
      }
    },
    500,
    [filterText]
  );

  const filteredData =
    data && searchByFields && searchByFields.length > 0
      ? data.filter((item: T) => {
          for (const field of searchByFields) {
            const valid =
              item[field] && (item[field] as any).toString().toLowerCase().includes(filterText.toLowerCase());

            if (valid) return true;
          }
          return false;
        })
      : data;

  useEffect(() => {
    setTotalRows(data ? data.length : 0);
    if (!loading) {
      // let's not show as many loading records as we feed the table, keep the max to the rowsperpage
      const rowsPerPage = customPaginationRowsPerPage ?? initialPerPage;
      additionalLoadingRows.current =
        data?.length && data?.length > 0 ? (data?.length > rowsPerPage ? rowsPerPage : data?.length - 1) : 0;
    }
  }, [data]);

  const handleClearSearch = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText('');
    }
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const TitleComp = isString(title) ? <h3>{title}</h3> : title;

    const displaySearch = Boolean(isServerSearch || (data?.length && searchByFields?.length));

    return (
      <div className="rs-table-header">
        {TitleComp}

        {subheaderComponent}

        {displaySearch && (
          <SearchComponent
            onSearch={(e: any) => setFilterText(e.target.value)}
            onClear={handleClearSearch}
            searchText={filterText}
          />
        )}
      </div>
    );
  }, [filterText, title, searchByFields, resetPaginationToggle, subheaderComponent]);

  const defaultSortColumn = React.useMemo(
    () => columns.find((col) => col.id === defaultSortColumnId || col.selector === defaultSortColumnId),
    [columns, defaultSortColumnId]
  );

  return (
    <div className="rs-table-component">
      {error ? (
        <p className="has-text-centered general-error-message">
          {isString(error) ? error : error.message || 'You may not have permission to access this'}
        </p>
      ) : (
        <DataTable
          columns={columns}
          data={filteredData || []}
          progressPending={Boolean(loading)}
          progressComponent={
            <div className="rs-table-no-data-component">
              <RsLoading />
              {[...Array(additionalLoadingRows.current)].map((_, i) => (
                <Fragment key={i}>
                  <div style={{ height: loadingRowHeight }}> </div>
                  <RsLoading />
                </Fragment>
              ))}
            </div>
          }
          persistTableHead
          subHeader={!noHeader}
          subHeaderComponent={subHeaderComponentMemo}
          noTableHead={noTableHead}
          noHeader={noHeader}
          className={className || ''}
          expandableRows={expandableRows}
          expandableRowDisabled={expandableRowDisabled}
          expandableRowsComponent={expandableRowsComponent}
          expandableIcon={{
            expanded: <i className={`fas fa-angle-right`}></i>,
            collapsed: <i className={`fas fa-angle-right`}></i>,
          }}
          customStyles={{
            headRow: {
              style: {
                minHeight: '37px',
                backgroundColor: '#f6f7f8',
                color: '#343a40',
              },
            },
            headCells: {
              style: {
                color: '#343a40',
                fontFamily: 'Nunito-ExtraBold',
                fontSize: '12.35px',
                letterSpacing: 'normal',
                textTransform: 'uppercase',
                transition: 'all .3s',
              },
              activeSortStyle: {
                color: '#5a709a',
                cursor: 'pointer',
              },
            },
            expanderButton: {
              style: {
                background: 'transparent',
              },
            },
            ...(props.customStyles || {}),
          }}
          noDataComponent={
            data?.length === 0 ? (
              <NoDataComponent message={noDataMessage} />
            ) : filteredData?.length === 0 ? (
              <NoDataComponent message={'No results found'} />
            ) : (
              ''
            )
          }
          highlightOnHover={clickableRows}
          pointerOnHover={clickableRows}
          conditionalRowStyles={conditionalRowStyles ?? []}
          onRowClicked={(row) => {
            if (!clickableRows) return;
            if (row.action) row.action();
            else if (onRowClicked) onRowClicked(row);
          }}
          // pagination
          pagination={!noPagination}
          paginationRowsPerPageOptions={customPaginationRowsPerPageOptions ?? [10, 15, 20, 25]}
          paginationPerPage={customPaginationRowsPerPage ?? initialPerPage}
          paginationTotalRows={paginationTotalRowsServer || totalRows}
          onChangePage={handlePageChangeServer}
          onChangeRowsPerPage={handleRowsPerPageChangeServer}
          paginationServer={isServerPagination}
          // sorting
          sortIcon={<i className="fas fa-sort-amount-down"></i>}
          defaultSortField={
            defaultSortColumnId
              ? defaultSortColumn?.id?.toString() || defaultSortColumn?.selector?.toString()
              : undefined
          }
          defaultSortAsc={defaultSortDirection !== 'desc'}
          onSort={handleSortServer}
          sortServer={isServerSort}
          sortFunction={props.sortFunction}
        />
      )}
    </div>
  );
}

export const RsTableExtended = RsTable;
