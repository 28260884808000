import * as Yup from 'yup';
import { defineApi } from '../common/api-definition';
import { LCELoggedCommandsMap } from '../loan/CommandType';
import { ObjectStripUnknown, RequiredArray } from '../validation/atoms';
import { NonEmptyString } from '../validation/base';

export type LoggedCommandsRequest = {
  loanIds: string[];
  mode?: 'raw-commands' | 'cached-commands';
};

export const loggedCommandsRequestValidation = ObjectStripUnknown({
  loanIds: RequiredArray(NonEmptyString.required()),
  mode: Yup.mixed().oneOf(['raw-commands', 'cached-commands']),
}).required();

export type LoggedCommandsResponse = {
  commands: LCELoggedCommandsMap;
};

export const loggedCommandsApi = defineApi<{
  body: LoggedCommandsRequest;
  response: LoggedCommandsResponse;
}>({
  url: 'platform-admin/logged-commands',
  method: 'post',
});
