import { string } from 'yup';
import { defineApi } from '../common/api-definition';
import { ObjectStripUnknown, RequiredArray } from '../validation/atoms';

export type CovenantOverviewView = {
  nextCommands: CovenantOverviewNextCommand[];
  events: CovenantOverviewEvent[];
};

export type CovenantOverviewNextCommand =
  | (CommonCovenantOverviewNextCommand & {
      type: 'covenant-collection';
      covenantId: string;
    })
  | (CommonCovenantOverviewNextCommand & {
      type: 'metric-collection';
    })
  | (CommonCovenantOverviewNextCommand & {
      type: 'sl-information-undertaking';
      informationUndertakingId: string;
    })
  | (CommonCovenantOverviewNextCommand & {
      type: 'sl-covenant-report';
      covenantReportId: string;
    });

export type CommonCovenantOverviewNextCommand = {
  loanId: string;
  loanName: string;
  commandName: string;
  details: string;
  checkDate: string;
  dueDate: string;
};

export type CovenantOverviewEvent = {
  type: 'metric-collection' | 'covenant-collection' | 'sl-covenant-report' | 'sl-information-undertaking';
  loanId: string;
  eventId: string;
  loanName: string;
  eventName: string;
  details: string;
  checkDate: string;
  dueDate: string;
  timestamp: string;
};

export const covenantOverviewViewRequestValidation = ObjectStripUnknown({
  loanIds: RequiredArray(string().required()),
}).required();

export type CovenantOverviewViewRequest = {
  loanIds: string[];
};

export const covenantOverviewViewApi = defineApi<{
  body: CovenantOverviewViewRequest;
  response: CovenantOverviewView;
}>({
  url: 'structured-loans/covenant-overview-view',
  method: 'post',
});
